import React from 'react'

import Layout from '../components/layout'
import Cover from '../components/cover'
import FooterAttention from '../components/footerAttention'

export default () => (
  <Layout>
    <Cover />
    <FooterAttention />
  </Layout>
)
