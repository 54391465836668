import { Back, Power0, Power2, TweenMax } from 'gsap';
import React from 'react';

import Image from '../images/intro.inline.svg';
import styles from '../sass/components/animation.module.scss';

export default class Animation extends React.Component {
  componentDidMount() {
    //stars
    TweenMax.staggerFromTo(
      document.querySelectorAll('#stars path'),
      2,
      { opacity: 0, scale: 0 },
      {
        opacity: 1,
        scale: 1,
        yoyoEase: true,
        repeat: -1,
        cycle: {
          delay: function() {
            return Math.random() * 10
          },
          repeatDelay: function() {
            return Math.random() * 2
          },
        },
      }
    )
    //clock
    TweenMax.to(document.querySelectorAll('#clock-hour'), 30, {
      rotation: 360,
      transformOrigin: 'center center',
      repeat: -1,
      ease: 'linear',
    })
    TweenMax.to(document.querySelectorAll('#clock-minute'), 5, {
      rotation: 360,
      transformOrigin: 'center center',
      repeat: -1,
      ease: 'linear',
    })
    //sun
    TweenMax.fromTo(
      document.querySelectorAll('#sun'),
      50,
      { rotation: 30, transformOrigin: 'center center' },
      {
        rotation: -200,
        transformOrigin: 'center center',
        repeat: -1,
        ease: 'linear',
      }
    )
    //sky
    TweenMax.to(document.querySelectorAll('#sky'), 22, {
      opacity: 0,
      repeat: -1,
      ease: 'linear',
      delay: 3,
      yoyo: true,
      repeatDelay: 3,
    })
    //car
    TweenMax.fromTo(
      document.querySelectorAll('#car'),
      15,
      { x: '1500' },
      {
        x: '-800',
        repeat: -1,
        repeatDelay: 5,
        ease: Power0.easeNone,
      }
    )
    TweenMax.to(document.querySelectorAll('#car-shadow'), 1, {
      scale: 1.05,
      transformOrigin: 'center center',
      repeat: -1,
      ease: Back.easeIn.config(3),
      yoyoEase: Back.easeOut.config(3),
    })
    TweenMax.to(document.querySelectorAll('#car-corpus'), 1, {
      y: -4,
      repeat: -1,
      ease: Back.easeIn.config(3),
      yoyoEase: Back.easeOut.config(3),
    })
    TweenMax.to(document.querySelectorAll('#car-beer path'), 1, {
      y: -3,
      repeat: -1,
      transformOrigin: 'bottom center',
      ease: Back.easeIn.config(3),
      yoyoEase: Back.easeOut.config(3),
    })
    //clouds
    TweenMax.staggerFromTo(
      document.querySelectorAll('#clouds path'),
      25,
      { x: 0, fill: '#8c9caa' },
      {
        repeat: -1,
        yoyo: true,
        fill: '#ccdddd',
        cycle: {
          x: function() {
            return Math.random() * (50 - 0) + 0
          },
        },
      }
    )
    TweenMax.staggerTo(document.querySelectorAll('#clouds g'), 25, {
      repeat: -1,
      yoyo: true,
      ease: Power2.easeInOut,
      cycle: {
        x: function() {
          return Math.random() * (200 - 50) + 50
        },
      },
    })

    TweenMax.fromTo(
      document.querySelectorAll('#fabric-shadow'),
      21.5,
      { skewX: '-60deg' },
      {
        skewX: '85deg',
        delay: 3.5,
        ease: Power0.easeNone,
        yoyo: true,
        repeat: -1,
        repeatDelay: 3.5,
      }
    )
  }

  render() {
    return (
      <figure className={styles.wrapper}>
        <Image />
      </figure>
    )
  }
}
