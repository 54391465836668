import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/cover.module.scss';
import Logo from '../svg/logo';
import { getUserData } from '../utils/userData';
import Container from './container';
import Link from './link';
import SvgAnimation from './svgAnimation';
import getString from '../utils/get-string';

const Cover = ({ desktopCoverImage, mobileCoverImage, strings }) => (
  <section className={styles.cover}>
    <Img
      fluid={desktopCoverImage.fluid}
      className={`${styles.cover__background} ${
        styles.cover__backgroundDesktop
      }`}
      style={{ position: '' }}
      imgStyle={{ objectPosition: '', objectFit: '' }}
      Tag="figure"
      alt={getString(strings, "11220819")}
      critical={true}
    />
    <Img
      fluid={mobileCoverImage.fluid}
      className={`${styles.cover__background} ${
        styles.cover__backgroundMobile
      }`}
      style={{ position: '' }}
      imgStyle={{ objectPosition: '', objectFit: '' }}
      Tag="figure"
      alt={getString(strings, "11220819")}
      critical={true}
    />
    <SvgAnimation />
    <Container>
      <div className={styles.cover__logo}>
        <Logo />
      </div>

      <header className={styles.cover__header}>
        <h1 className={styles.cover__title}>
          {getString(strings, "11220819")}
        </h1>
      </header>

      <footer className={styles.cover__footer}>
        <Link
          to={getUserData() ? '/lessons' : '/register'}
          className={`${styles.cover__footer__button} ${
            styles.cover__footer__buttonRegister
          }`}
        >
          {getUserData() ? getString(strings, "11221034") : getString(strings, "11220820")}
        </Link>
        <Link
          to={getUserData() ? '/log-out' : '/login'}
          className={`${styles.cover__footer__button} ${
            styles.cover__footer__buttonLogin
          }`}
        >
          {getUserData() ? getString(strings, "11220926") : getString(strings, "11220821")}
        </Link>
      </footer>
    </Container>
  </section>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        desktopCoverImage: imageSharp(
          original: { src: { regex: "/cover-desktop/" } }
        ) {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }

        mobileCoverImage: imageSharp(
          original: { src: { regex: "/cover-mobile/" } }
        ) {
          fluid(maxWidth: 990) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }

        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220819", "11220820", "11220926", "11221034", "11220821"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Cover {...data} {...props} />}
  />
)
