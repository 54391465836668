import React from 'react'
import { graphql, StaticQuery } from 'gatsby';
import Container from './container';
import SvgAttention from '../svg/attention';
import getString from '../utils/get-string';

import styles from '../sass/components/footer-attention.module.scss'

const FooterAttention = ({ strings }) => (
  <div className={styles.footerAttention}>
    <Container>
      <div className={styles.footerAttentionInner}>
        <p>{getString(strings, "14129723")}</p>
        <SvgAttention />
      </div>
    </Container>
  </div>
)


export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["14129723"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <FooterAttention {...data} {...props} />}
  />
)
